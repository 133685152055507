@use '@ingka/variables/style.scss' as variables;

.imageWrapper {
    background: variables.$colour-neutral-1;
    display: flex;
    justify-content: center;
    padding: variables.$space-75;
    aspect-ratio: 1 / 1;
    max-height: variables.$space-550;
    flex: 1;
}

.imagesContainer {
    width: 100%;
    display: flex;
    gap: variables.$space-50;
}

.imagePlaceholder {
    width: auto !important;
}
