@use '@ingka/variables/style.scss' as variables;

.wrapper {
    width: 100%;
    position: relative;
}

.container {
    width: 100%;
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0;
    text-align: left;
    position: relative;
    padding: variables.$space-75;
    background-color: variables.$colour-neutral-2;
    span {
        display: flex;
        flex-direction: column;
        gap: variables.$space-75;
    }
}

.content {
    width: 100%;
    padding-right: variables.$space-250;
}

.caption {
    font-weight: variables.$font-weight-regular;
}

.listName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.settingsButton {
    position: absolute;
    top: variables.$space-75;
    right: variables.$space-75;
}
