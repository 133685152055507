@use '@ingka/variables/style.scss' as variables;

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: variables.$space-125;
    @include variables.md {
        margin-bottom: variables.$space-150;
    }
    @include variables.xl {
        width: 50%;
    }
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: variables.$space-100;
}

.marginBottom {
    margin-bottom: variables.$space-100;
}
