@use '@ingka/variables/style.scss' as variables;

.wrapper {
    display: grid;
    gap: variables.$space-100;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: variables.$space-125;

    @include variables.md {
        margin-bottom: variables.$space-150;
        gap: variables.$space-150;
        grid-template-columns: repeat(2, 1fr);
    }

    @include variables.lg {
        grid-template-columns: repeat(3, 1fr);
    }

    @include variables.xl {
        grid-template-columns: repeat(4, 1fr);
    }
}
