@use '@ingka/variables/style.scss' as variables;

.wrapper {
    width: 100%;
    max-height: unset;
    overflow: hidden;
    aspect-ratio: 1/1;
    & > * {
        position: unset !important;
    }
}

.innerWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$colour-neutral-2;
}
