@use '@ingka/variables/style.scss' as variables;

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.countBadge {
    line-height: inherit;
    &::before {
        content: '+';
    }
}
