@use '@ingka/variables/style.scss' as variables;

.wrapper {
    grid-column: 1 / 6;
    @include variables.md {
        grid-column: 1 / 7;
    }
    @include variables.lg {
        grid-column: 1 / 13;
    }
}
